import React, { useContext } from "react"
import { CalculatorContext } from "../../providers/calculator-provider"

const CalculatorTextarea = ({
  name,
  type,
  className = "",
  register,
  ...props
}) => {
  const { calculatorData, dispatchCalculatorData } = useContext(
    CalculatorContext
  )

  const handleOnChange = (e = {}) => {
    const target = e?.target
    let value = target?.value

    dispatchCalculatorData({ type: "setValue", key: name, value })
  }

  return (
    <textarea
      name={name}
      ref={register}
      defaultValue={calculatorData[name] || ""}
      onChange={handleOnChange}
      className={`${className} border-gray-400 border py-1 px-2`}
      {...props}
    />
  )
}

export default CalculatorTextarea
