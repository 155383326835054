import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/nj-logo.svg"

import { ErrorMessage } from "@hookform/error-message"
import { useForm } from "react-hook-form"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  CalculatorInput,
  ShoppingListNumber,
  ShoppingListItem,
  ShoppingListLabel,
  CalculatorNav,
  CalculatorCopyUrlButton,
} from "../../components/calculator"
import locale from "../../locale/en"
import CalculatorDataValue from "../../components/calculator/calculator-data-value"
import {
  getPreviousSection,
  SHOPPING_LIST_KEY,
} from "../../lib/calculator-sections"
import { useSectionComplete } from "../../hooks/useSectionComplete"
import CalculatorTextarea from "../../components/calculator/calculator-textarea"
import BackLink from "../../components/back-link"

const previousSection = getPreviousSection(SHOPPING_LIST_KEY)

const { faq } = locale

const ShoppingListActions = ({ className = "" }) => (
  <div className={`m-10 print:hidden ${className}`}>
    <button
      className="btn btn-primary btn-small mr-5"
      onClick={() => window.print()}
    >
      Print List
    </button>
    <CalculatorCopyUrlButton />
    <div className="mt-5">
      <Link
        to={`/calculator/${previousSection}`}
        state={{ updateScroll: false, anchor: "calculator-nav" }}
      >
        Go back to edit inputs
      </Link>
    </div>
  </div>
)

const ShoppingList = () => {
  const { register, errors } = useForm({ mode: "onChange" })

  useSectionComplete(SHOPPING_LIST_KEY)

  return (
    <Layout>
      <SEO title={"PPE Shopping List"} />

      <div className="prose max-w-none mb-10 print:mb-2">
        <BackLink />
        <div className="items-center gap-4 hidden print:flex">
          <img className="h-16" src={Logo} alt="New Jersey logo" />
          <p className="font-bold text-xl">NJ PPE Access Program</p>
        </div>
        <h1>PPE Shopping List</h1>
        <h2 className="print:hidden">You're all done!</h2>
        <p className="print:hidden">
          All estimates are subject to your ultimate discretion. Values will
          automatically update for you to print or email as you plan for the
          future! You can also share this list with others by sending them the
          URL.
        </p>
      </div>

      <div className="bg-backgroundHighlight print:bg-white">
        <CalculatorNav className="print:hidden" />

        <div className="font-bold text-xl mx-10 my-5 print:mx-0 print:mb-2">
          For the next{" "}
          <form className="inline-block print:hidden" onSubmit={() => {}}>
            <CalculatorInput
              type="number"
              name={"number_of_weeks_to_plan_for"}
              className={`w-10 ${
                errors.number_of_weeks_to_plan_for ? "border-red-400" : ""
              }`}
              register={register({
                min: {
                  value: 1,
                  message: "Must be greater than 0",
                },
                required: "Must be greater than 0",
              })}
            />{" "}
          </form>
          <span className="hidden print:inline-block">
            <CalculatorDataValue dataKey="number_of_weeks_to_plan_for" />
          </span>{" "}
          weeks, your projected PPE needs are:
          <div className="text-red-400">
            <ErrorMessage errors={errors} name="number_of_weeks_to_plan_for" />
          </div>
        </div>

        <ShoppingListActions />

        <div className="m-10 print:flex print:justify-between print:m-0">
          <div className="flex flex-col flex-grow gap-y-10 print:gap-y-4">
            <div>
              <div className="font-bold mb-4 print:mb-1">{faq.face_masks}</div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="cloth_masks_per_employee_total" />
                  <ShoppingListLabel>cloth masks</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="disposable_masks_per_employee_total" />
                  <ShoppingListLabel>
                    disposable surgical masks
                  </ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="x95_masks_per_employee_total" />
                  <ShoppingListLabel>
                    KN95 and N95 respirators
                  </ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">
                {faq.disposable_gloves}
              </div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="nitrile_gloves_per_employee_total" />
                  <ShoppingListLabel>nitrile gloves</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="rubber_gloves_per_employee_total" />
                  <ShoppingListLabel>rubber gloves</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="polychloroprene_gloves_per_employee_total" />
                  <ShoppingListLabel>polychloroprene gloves</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="vinyl_gloves_per_employee_total" />
                  <ShoppingListLabel>vinyl gloves</ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">
                {faq.face_shields}
              </div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="face_shields_per_employee_total" />
                  <ShoppingListLabel>face shields</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="goggles_per_employee_total" />
                  <ShoppingListLabel>goggles</ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">
                {faq.hand_sanitizer}
              </div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="hand_sanitizer_customer_total_oz" />
                  <ShoppingListLabel>ounces for customers</ShoppingListLabel>
                </ShoppingListItem>

                <ShoppingListItem>
                  <ShoppingListNumber dataKey="hand_sanitizer_employee_total_oz" />
                  <ShoppingListLabel>ounces for employees</ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">
                {faq.disinfectants}
              </div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="disinfectants_wipes_containers_total" />
                  <ShoppingListLabel>
                    disinfectant wipes containers
                  </ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="disinfectants_spray_bottles_total" />
                  <ShoppingListLabel>
                    <CalculatorDataValue
                      dataKey={"disinfectants_sprays_bottle_size"}
                    />{" "}
                    spray bottles
                  </ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="disinfectants_liquid_cleaners_total" />
                  <ShoppingListLabel>
                    <CalculatorDataValue
                      dataKey={"disinfectants_liquid_cleaners_bottle_size"}
                    />{" "}
                    liquid cleaner bottles
                  </ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">
                {faq.impermeable_dividers}
              </div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="impermeable_dividers_total" />
                  <ShoppingListLabel>
                    <CalculatorDataValue
                      dataKey={"impermeable_dividers_height"}
                    />
                    " ×{" "}
                    <CalculatorDataValue
                      dataKey={"impermeable_dividers_width"}
                    />
                    "{" "}
                    <CalculatorDataValue
                      dataKey={"impermeable_dividers_preferred_material"}
                    />{" "}
                    dividers
                  </ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div>
              <div className="font-bold mb-4 print:mb-2">{faq.signage}</div>

              <div className="flex gap-5 text-center print:flex-col print:gap-1 print:text-left">
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="signage_floor_markers_total" />
                  <ShoppingListLabel>floor markers</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="signage_signage_total" />
                  <ShoppingListLabel>signs</ShoppingListLabel>
                </ShoppingListItem>
                <ShoppingListItem>
                  <ShoppingListNumber dataKey="signage_rope_total" />
                  <ShoppingListLabel>
                    rope and stanchion systems
                  </ShoppingListLabel>
                </ShoppingListItem>
              </div>
            </div>

            <div className="print:hidden">
              <div className="font-bold mb-4">Notes</div>

              <p>Feel free to write some notes for yourself.</p>

              <form className="flex">
                <CalculatorTextarea
                  name={"notes"}
                  register={register}
                  className="w-full h-24"
                />
              </form>
            </div>
          </div>
          <div className="hidden print:block print:max-w-sm">
            <div className="prose">
              <p className="font-bold">Notes</p>
              <p>
                <CalculatorDataValue dataKey="notes" alt="(No notes)" />
              </p>
            </div>
          </div>
        </div>

        <ShoppingListActions className="pb-10" />
      </div>
    </Layout>
  )
}
export default ShoppingList
